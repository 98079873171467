<template>
  <div class="container">

    <div class="graduate-work-temp mt-4 mb-4">
      <h4 class="text-center mb-4">Темы для выпускных работ (временная страница)</h4>

      <AddGraduateWorkThemeTempModal/>

      <div class="row mb-4">
        <div class="col-md-12">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#addGraduateWorkThemeTempModal">
            <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить тему выпускной работы
          </button>
        </div>
      </div>

      <DataTable :value="graduateWorkTemp.graduateWorkThemes" :paginator="true" :rows="10"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 filterDisplay="menu"
                 v-model:filters="filters"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                 stripedRows responsiveLayout="scroll">

<!--        <Column field="theme_name_kz" header="Название темы на казахском"></Column>-->
<!--        <Column field="theme_name_ru" header="Название темы на русском"></Column>-->
<!--        <Column field="theme_name_en" header="Название темы на английском"></Column>-->
        <Column header="Название темы">
          <template #body="{data}">
            <div v-if="data.students[0].student_study_language_id == 82">
              {{data.theme_name_kz}}
            </div>
            <div v-else-if="data.students[0].student_study_language_id == 137">
              {{data.theme_name_ru}}
            </div>
            <div v-else>
              {{data.theme_name_en}}
            </div>
          </template>
        </Column>
        <Column field="education_program_code" header="Код ОП"></Column>
        <Column field="study_level_name" header="Уровень обучения">

          <template #filter="{filterModel}">
            <Dropdown v-model="filterModel.value" :options="studyLevels" placeholder="Любой"
                      class="p-column-filter" :showClear="true">
              <template #value="slotProps">
                <span v-if="slotProps.value">{{slotProps.value}}</span>
                <span v-else>{{slotProps.placeholder}}</span>
              </template>
              <template #option="slotProps">
                <span>{{slotProps.option}}</span>
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column field="academic_year_name" header="Учебный год"></Column>
        <Column header="Автор темы">
          <template #body="{data}">
            {{data.lastname}} {{data.firstname}}
          </template>
        </Column>
        <Column header="Тип">
          <template #body="{data}">
            <div v-if="data.is_project == 1">
              Проект
            </div>
            <div v-else>
              Выпускная работа
            </div>
          </template>
        </Column>
        <Column header="Студенты">
          <template #body="{data}">

            <div v-if="data.students[0].student_id != null">
              <div v-for="(student, studentIndex) in data.students" :key="'student'+studentIndex" class="mb-2">
                <div class="row mt-4">
                  <div class="col-md-6">
                    <div class="remove-item__button d-flex flex-row-reverse">
                      <i class="fa fa-times" @click="confirmDeleteSelectionTheme(student.selection_theme_id)"></i>
                    </div>
                    {{student.student_lastname}} {{student.student_firstname}}
                    <!--                  <a href="#" @click="openEditChooseStudents(data.id,student.selection_theme_id, student.student_id)">-->
                    <!--                  {{student.student_lastname}} {{student.student_firstname}}-->
                    <!--                </a>-->
                  </div>
                  <!--                <div class="col-md-6">-->
                  <!--                  <Button @click="confirmDeleteSelectionTheme(student.selection_theme_id)" icon="pi pi-trash" class="p-button-danger p-mr-2"></Button>-->
                  <!--                </div>-->
                </div>

              </div>
            </div>
            <div v-else>
              <span>Студенты не выбраны</span>
            </div>

          </template>
        </Column>
        <Column header="Действия">

          <template #body="{data}">

            <div class="mb-2" v-if="data.is_project == 1">

              <Button icon="pi pi-user-plus" @click="openChooseStudentsProjectDialog(data.id)"/>

            </div>

              <div class="mb-2" v-else>
                <Button icon="pi pi-user-plus" @click="openChooseStudentDialog(data.id)"/>
              </div>
            <Button class="p-button-warning me-1" icon="pi pi-pencil" @click="openEditGraduateWorkThemeDialog(data.id)"/>
            <Button class="p-button-danger" icon="pi pi-trash" @click="confirmDeleteGraduateWorkTheme(data.id)"/>


          </template>


        </Column>





      </DataTable>



      <Dialog v-model:visible="chooseStudentDisplay" :style="{width: '50vw'}">
        <template #header>
          <h5>Выбор студента</h5>
        </template>

        <div style="height: 200px" class="col-md-12 mt-4">
          <div class="form-group row mt-4">
            <label for="student_id" class="col-md-3 col-form-label">Студент</label>
            <div class="col-md-9" id="student_id">
              <select class="form-control form-select"
                      @input="changeStudentId($event)">
                <option v-for="(item, index) in [{id: 0, last_name: 'Выберите студента'}, ...graduateWorkTemp.students]"
                        :value="item.id"
                        :key="index">{{ item.last_name }} {{ item.first_name }}
                </option>
              </select>
            </div>
          </div>

        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeChooseStudentDialog"/>
          <Button label="Сохранить" icon="pi pi-check" @click="submitSelectionTheme"/>
        </template>
      </Dialog>

      <Dialog v-model:visible="editGraduateWorkThemeDisplay" :style="{width: '50vw'}">
        <template #header>
          <h5>Поменять тему</h5>
        </template>

        <div class="col-md-12 mt-4">
          <div class="form-group row mt-4">
            <label for="theme_name_kz" class="col-md-3 col-form-label">Название
              темы на казахском</label>
            <div class="col-md-9" id="theme_name_kz">
                      <textarea v-model="selectedGraduateWorkTheme.theme_name_kz" class="form-control" placeholder="Название темы"></textarea>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label for="theme_name_ru" class="col-md-3 col-form-label">Название
              темы на русском</label>
            <div class="col-md-9" id="theme_name_ru">
                      <textarea v-model="selectedGraduateWorkTheme.theme_name_ru" class="form-control" placeholder="Название темы"></textarea>
            </div>
          </div>

          <div class="form-group row mt-4 mb-4">
            <label for="theme_name_en" class="col-md-3 col-form-label">Название
              темы на английском</label>
            <div class="col-md-9" id="theme_name_en">
                      <textarea v-model="selectedGraduateWorkTheme.theme_name_en" class="form-control" placeholder="Название темы"></textarea>
            </div>
          </div>

        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeEditGraduateWorkThemeDialog"/>
          <Button label="Сохранить" icon="pi pi-check" :loading="loadingUpdateTheme" @click="updateGraduateWorkTheme"/>
        </template>
      </Dialog>


<!--      <Dialog v-model:visible="editChooseStudentDisplay" :style="{width: '50vw'}">-->
<!--        <template #header>-->
<!--          <h5>Редактировать студента</h5>-->
<!--        </template>-->

<!--        <div style="height: 200px" class="col-md-12 mt-4">-->
<!--          <div class="form-group row mt-4">-->
<!--            <label class="col-md-3 col-form-label">Студент</label>-->
<!--            <div class="col-md-9">-->
<!--              <select class="form-control form-select"-->
<!--                      @input="changeEditChooseStudentId($event)">-->
<!--                <option v-for="(item, index) in [{id: 0, last_name: 'Выберите студента'}, ...graduateWorkTemp.students]"-->
<!--                        :value="item.id"-->
<!--                        :selected="item.id == editStudentId"-->
<!--                        :key="index">{{ item.last_name }} {{ item.first_name }}-->
<!--                </option>-->
<!--              </select>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->

<!--        <template #footer>-->
<!--          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeEditChooseStudentDialog"/>-->
<!--          <Button label="Обновить" icon="pi pi-check" @click="putSelectionTheme"/>-->
<!--        </template>-->
<!--      </Dialog>-->


      <Dialog v-model:visible="chooseStudentsProjectDisplay" :style="{width: '50vw'}">
        <template #header>
          <h5>Выбор студентов (проект)</h5>
        </template>

        <div style="height: 200px" class="col-md-12 mt-4">
          <div class="form-group row mt-4">
            <label for="project_student_id" class="col-md-3 col-form-label">Студент</label>
            <div class="col-md-9" id="project_student_id">
              <MultiSelect v-model="selectedProjectStudents" :options="graduateWorkTemp.students" optionLabel="fullname" :selectionLimit="3" placeholder="Выберите студентов" />
<!--              {{selectedProjectStudents}}-->
<!--              <select class="form-control form-select"-->
<!--                      @input="changeStudentId($event)">-->
<!--                <option v-for="(item, index) in [{id: 0, last_name: 'Выберите студента'}, ...graduateWorkTemp.students]"-->
<!--                        :value="item.id"-->
<!--                        :key="index">{{ item.last_name }} {{ item.first_name }}-->
<!--                </option>-->
<!--              </select>-->
            </div>
          </div>

        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeChooseStudentsProjectDialog"/>
          <Button label="Сохранить" icon="pi pi-check" @click="submitSelectionTheme"/>
        </template>
      </Dialog>


<!--      <Toast />-->
      <ConfirmDialog></ConfirmDialog>
    </div>
  </div>

  <!--  </div>-->
</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import AddGraduateWorkThemeTempModal from '@/components/graduate-work/departmentHead/AddGraduateWorkThemeTempModal.vue'
export default {
  name: "GraduateWorkTemp",
  components: {
    AddGraduateWorkThemeTempModal
  },
  data() {
    return {
      chooseStudentDisplay: false,
      chooseStudentsProjectDisplay: false,
      selectedProjectStudents: null,
      selectAll: false,
      editChooseStudentDisplay: false,

      editGraduateWorkThemeDisplay: false,
      selectedGraduateWorkTheme: {},
      loadingUpdateTheme: false,

      editStudentId: null,
      filters: {
        'study_level_name': {value: null, matchMode: FilterMatchMode.EQUALS},
      },
      studyLevels: ['Бакалавриат', 'Магистратура (1 годичное,профильное)', 'Магистратура (1,5 годичное, профильное)',
        'Магистратура (2 годичное, научно-педагогическое)', 'Докторантура (научно-педагогическое направление)', 'MBA', 'EMBA'],
    }
  },
  computed: {
    ...mapState('graduateWorkTemp', ['graduateWorkTemp']),
    // getStudentFullName() {
    //   let last_name = this.graduateWorkTemp.students.last_name
    //   let first_name = this.graduateWorkTemp.students.first_name
    //   return  last_name + first_name
    // },
  },

  methods: {
    ...mapActions('graduateWorkTemp', ['GET_STUDY_LEVELS', 'GET_GRADUATE_WORK_THEMES', 'POST_SELECTION_THEME',
      'GET_STUDENTS_BY_EDUCATION_PROGRAM_CODE', 'PUT_SELECTION_THEME', 'DELETE_SELECTION_THEME', 'DELETE_GRADUATE_WORK_THEME', 'PUT_UPDATE_GRADUATE_WORK_THEME']),
    ...mapMutations('graduateWorkTemp', ['SET_GRADUATE_WORK_THEME_ID','SET_SELECTION_THEME_STUDENT_ID',
      'SET_SELECTION_THEME_STUDENTS_PROJECT', 'SET_EDIT_SELECTION_THEME_STUDENT_ID', 'SET_SELECTION_THEME_ID']),

    async updateGraduateWorkTheme() {
      this.loadingUpdateTheme = true


      let putForm = {
        id: this.selectedGraduateWorkTheme.id,
        theme_name_kz: this.selectedGraduateWorkTheme.theme_name_kz,
        theme_name_en: this.selectedGraduateWorkTheme.theme_name_en,
        theme_name_ru: this.selectedGraduateWorkTheme.theme_name_ru,
      }
      console.log(putForm, 'putForm updateSelectionTheme')
      const res = await this.PUT_UPDATE_GRADUATE_WORK_THEME(putForm)
      if (res) {
        this.editGraduateWorkThemeDisplay = false
        await this.GET_GRADUATE_WORK_THEMES()
        this.$message({title: 'Обновление', text: 'Тема успешна обновилась'})
      }
      this.loadingUpdateTheme = false
    },
    openEditGraduateWorkThemeDialog(id) {
      this.editGraduateWorkThemeDisplay = true
      this.selectedGraduateWorkTheme = this.graduateWorkTemp.graduateWorkThemes.find(i=>i.id == id)
      console.log(this.selectedGraduateWorkTheme, 'selectedGraduateWorkTheme')
    },
    closeEditGraduateWorkThemeDialog() {
      this.editGraduateWorkThemeDisplay = false
    },
    confirmDeleteSelectionTheme(id) {
      this.$confirm.require({
        message: 'Вы уверены что хотите удалить студента?',
        header: 'Удаление студента',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {

          await this.DELETE_SELECTION_THEME(id)
          await this.GET_GRADUATE_WORK_THEMES()
        }
      });
    },
    confirmDeleteGraduateWorkTheme(id) {
      this.$confirm.require({
        message: 'Вы уверены что хотите удалить темы?',
        header: 'Удаление темы',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {

          await this.DELETE_GRADUATE_WORK_THEME(id)
          await this.GET_GRADUATE_WORK_THEMES()
        }
      });
    },
    async putSelectionTheme() {
      this.editChooseStudentDisplay = false
      await this.PUT_SELECTION_THEME()
      await this.GET_GRADUATE_WORK_THEMES()
    },
    async openEditChooseStudents(selectedGraduateWorkThemeId, selection_theme_id, student_id) {
      console.log(selection_theme_id, 'edit selection theme id')
      console.log(student_id, 'edit student_id')
      this.editStudentId = student_id
      this.editChooseStudentDisplay = true
      await this.SET_GRADUATE_WORK_THEME_ID(selectedGraduateWorkThemeId)
      await this.SET_SELECTION_THEME_ID(selection_theme_id)
      await this.GET_STUDENTS_BY_EDUCATION_PROGRAM_CODE()
    },
    closeEditChooseStudentDialog() {
      this.editChooseStudentDisplay = false
    },
    async openChooseStudentDialog(selectedGraduateWorkThemeId) {
      console.log(selectedGraduateWorkThemeId, 'selectedGraduateWorkThemeId')
      this.graduateWorkTemp.selectedGraduateWorkThemeId = 0
      this.graduateWorkTemp.selectedStudentId = 0
      this.chooseStudentDisplay = true
      await this.SET_GRADUATE_WORK_THEME_ID(selectedGraduateWorkThemeId)
      await this.GET_STUDENTS_BY_EDUCATION_PROGRAM_CODE()
    },
    async openChooseStudentsProjectDialog(selectedGraduateWorkThemeId) {
      console.log(selectedGraduateWorkThemeId, 'selectedGraduateWorkThemeId')
      this.chooseStudentsProjectDisplay = true
      await this.SET_GRADUATE_WORK_THEME_ID(selectedGraduateWorkThemeId)
      await this.GET_STUDENTS_BY_EDUCATION_PROGRAM_CODE()

    },
    closeChooseStudentDialog() {
      this.chooseStudentDisplay = false
      this.graduateWorkTemp.selectedGraduateWorkThemeId = 0
      this.graduateWorkTemp.selectedStudentId = 0
    },
    closeChooseStudentsProjectDialog() {
      this.chooseStudentsProjectDisplay = false
      this.selectedProjectStudents = null
      this.graduateWorkTemp.selectedGraduateWorkThemeId = 0
      this.graduateWorkTemp.selectedStudentId = 0

    },
    changeStudentId(e) {
      const value = e.target.value
      // this.selectedStudentId= value
      // console.log(this.selectedStudentId, "selectedStudentId")
      this.SET_SELECTION_THEME_STUDENT_ID(value)



    },
    changeEditChooseStudentId(e) {
      const value = e.target.value
      this.SET_EDIT_SELECTION_THEME_STUDENT_ID(value)
    },
    async submitSelectionTheme(){

      await this.SET_SELECTION_THEME_STUDENTS_PROJECT(this.selectedProjectStudents)

      // await this.POST_SELECTION_THEME().then(res => {
      //   if (res) {
      //     this.chooseStudentDisplay = false
      //     this.GET_GRADUATE_WORK_THEMES()
      //
      //     this.$message({title: 'Добавление', text: 'Данные успешно добавились'})
      //   } else {
      //     const errorText = res.errors[0].map(err => err.message).join('\n');
      //     this.$error({title: 'Добавление', text: errorText})
      //   }
      // })


      const res = await this.POST_SELECTION_THEME()
      if (res) {
        this.chooseStudentDisplay = false
        this.GET_GRADUATE_WORK_THEMES()
        this.$message({title: 'Добавление', text: 'Данные успешно добавились'});
      } else {
        this.$error({title: 'Добавление', text: 'Произошла ошибка'})
      }


      this.graduateWorkTemp.selectedGraduateWorkThemeId = 0
      this.graduateWorkTemp.selectedStudentId = 0
    }

  },

  async mounted() {
    await this.GET_GRADUATE_WORK_THEMES()
  }
}
</script>

<style scoped>
.remove-item__button {
  color: red;
}
</style>
